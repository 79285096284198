import { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { nav, heroMenu } from "../../Data";
import "./style.css";

const Menu = ({ hide, onShow }) => {
  // store all Nav node
  const nodeListRef = [];

  const [currIndex, setcurrIndex] = useState("0");

  const handleMouseover = (e) => {
    // reset all text opacity to 1
    resetAllTextOpacity();
    // get the active curr_index and setcurrIndex state
    setcurrIndex(e.target.getAttribute("curr_index"));
    // make the active nav item opacity to 0.35
    e.target.style.opacity = 0.35;
  };

  // nav: all text opacity become 1
  const resetAllTextOpacity = () => {
    nodeListRef.forEach((nodeList) => {
      nodeList.style.opacity = 1;
    });
  };

  return (
    <div className={hide ? "menu" : "menu show"}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 left is-relative">
            <button className="menu-text" onClick={onShow}>
              <span className="desktop-version767">Close</span>
              <img
                className="cross-icon mobile-version767"
                src="../images/icon/cross.png"
                alt="burger icon"
              />
            </button>
            <div className="nav-wrapper">
              <ul>
                {nav.map((navObj, index) => {
                  return (
                    <li key={index}>
                      <NavLink
                        ref={(c) => nodeListRef.push(c)}
                        to={navObj.path}
                        className="nav"
                        onMouseOver={handleMouseover}
                        onClick={onShow}
                        curr_index={index}
                      >
                        {navObj.text}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
              <div className="btn-wrapper">
                <Link
                  to="https://calendly.com/shannonlynn-info/30min?month=2025-01"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-shannon book-btn"
                  onClick={onShow}
                >
                  Book a virtual tour
                </Link>
                <Link
                  to="/register"
                  className="btn-shannon register-btn"
                  onClick={onShow}
                >
                  Register Now
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-6 right"
            style={{
              padding: "0px",
              backgroundImage: `url(${heroMenu[currIndex].path})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
