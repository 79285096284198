import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import gsap from "gsap";
import "./style.css";

const PopUp = () => {
  const [matches, setMatches] = useState(false);
  // header
  const [show, setShow] = useState(true);
  const handleClick = () => {
    setShow(!show);
  };
  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      setMatches(true);
    }
  }, []);
  return (
    <div
      className="popup-container"
      style={{ visibility: show ? "visible" : "hidden", opacity: show ? 1 : 0 }}
    >
      <div className="wrapper">
        <div className="popup">
          <img
            className="cross"
            src="/images/icon/cross-white.png"
            alt="cross icon"
            onClick={(e) => handleClick()}
          />
          <img
            className="logo"
            src="/images/logo/Shannon-Lynn_Logo_White-m.png"
            alt="shannon-lynn logo"
          />
          <div className="heading">
            Presentation Centre <span>Now Open</span>
          </div>
          <p>
            Come see our lake views, features and finishes,
            <br /> and learn about exciting, limited time buyer
            <br /> incentives and bonuses.
          </p>
          <div className="time">
            {/* Tuesday to Saturday <br />
            12pm to 5pm <br /> */}
            2739 Shannon Lake Road
          </div>
          <Link
            to="https://calendly.com/shannonlynn-info/30min?month=2025-01"
            target="_blank"
            rel="noopener noreferrer"
            className="btn-shannon book-btn"
            onClick={(e) => handleClick()}
          >
            Book Your Tour
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
