import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Menu from "../Menu";
import { useLenis } from "@studio-freight/react-lenis";
import gsap from "gsap";
import "./style.css";

const Header = ({display}) => {
  const location = useLocation();

  const [isWhite, setColor] = useState({
    isLogoWhite: "0",
    menuColor: "#3B3B3B",
    headerBorderColor: "#3B3B3B",
    headerBgColor: "transparent",
    transform: "translateY(0%)",
    opacity: 1,
  });

  const [hide, setHide] = useState(true);
  let scrollPos = 0;

  useLenis(({ scroll }) => {
    // handle scroll -- page with banner
    const scrollhandle = () => {
      if (scroll < window.innerHeight) {
        setColor({
          isLogoWhite: "1", //gaile
          menuColor: "#FFFFFF",
          headerBorderColor: "#FFFFFF",
          headerBgColor: "transparent",
          transform: "translateY(0%)",
          opacity: 1,
        });
        if (location.pathname === "/interiors-features") {
          setColor({
            isLogoWhite: "0",
            menuColor: "#3B3B3B",
            headerBorderColor: "#3B3B3B",
            headerBgColor: "transparent",
            transform: "translateY(0%)",
            opacity: 1,
          });
        }
      } else if (scroll > scrollPos) {
        setColor({
          isLogoWhite: "0",
          menuColor: "#3B3B3B",
          headerBorderColor: "#3B3B3B",
          headerBgColor: "#D8D5D1",
          transform: "translateY(-100%)",
          opacity: 0,
        });
        if (location.pathname === "/location-lifestyle") {
          setColor({
            isLogoWhite: "1",
            menuColor: "#FFFFFF",
            headerBorderColor: "#FFFFFF",
            headerBgColor: "#223957",
            transform: "translateY(-100%)",
            opacity: 1,
          });
        }
      } else if (scroll < scrollPos) {
        setColor({
          isLogoWhite: "0",
          menuColor: "#3B3B3B",
          headerBorderColor: "#3B3B3B",
          headerBgColor: "#D8D5D1",
          transform: "translateY(0%)",
          opacity: 1,
        });
        if (location.pathname === "/location-lifestyle") {
          setColor({
            isLogoWhite: "1",
            menuColor: "#FFFFFF",
            headerBorderColor: "#FFFFFF",
            headerBgColor: "#223957",
            transform: "translateY(0%)",
            opacity: 1,
          });
        }
      }
      scrollPos = scroll;
    };
    // handle scroll -- page without banner
    const scrollhandleNoBnr = () => {
      if (scroll === 0) {
        if (
          location.pathname === "/register" ||
          location.pathname === "/thankyou" ||
          location.pathname === "/privacyPolicy"
        ) {
          setColor({
            isLogoWhite: "1",
            menuColor: "#FFFFFF",
            headerBorderColor: "#FFFFFF",
            headerBgColor: "transparent",
            transform: "translateY(0%)",
            opacity: 1,
          });
        } else {
          setColor({
            isLogoWhite: "0",
            menuColor: "#3B3B3B",
            headerBorderColor: "#3B3B3B",
            headerBgColor: "transparent",
            transform: "translateY(0%)",
            opacity: 1,
          });
        }
      } else if (scroll > scrollPos) {
        if (
          location.pathname === "/register" ||
          location.pathname === "/thankyou" ||
          location.pathname === "/privacyPolicy"
        ) {
          setColor({
            isLogoWhite: "1",
            menuColor: "#FFFFFF",
            headerBorderColor: "#FFFFFF",
            headerBgColor: "#223957",
            transform: "translateY(-100%)",
            opacity: 1,
          });
        } else {
          setColor({
            isLogoWhite: "0",
            menuColor: "#3B3B3B",
            headerBorderColor: "#3B3B3B",
            headerBgColor: "#D8D5D1",
            transform: "translateY(-100%)",
            opacity: 0,
          });
        }
      } else if (scroll < scrollPos) {
        if (
          location.pathname === "/register" ||
          location.pathname === "/thankyou" ||
          location.pathname === "/privacyPolicy"
        ) {
          setColor({
            isLogoWhite: "1",
            menuColor: "#FFFFFF",
            headerBorderColor: "#FFFFFF",
            headerBgColor: "#223957",
            transform: "translateY(0%)",
            opacity: 1,
          });
        } else {
          setColor({
            isLogoWhite: "0",
            menuColor: "#3B3B3B",
            headerBorderColor: "#3B3B3B",
            headerBgColor: "#D8D5D1",
            transform: "translateY(0%)",
            opacity: 1,
          });
        }
      }
      scrollPos = scroll;
    };

    // HEADER COLOR CHANGE
    if (
      location.pathname === "/" ||
      location.pathname === "/floorplans" ||
      location.pathname === "/location-lifestyle" ||
      location.pathname === "/gallery" ||
      location.pathname === "/team" ||
      location.pathname === "/register" ||
      location.pathname === "/thankyou" ||
      location.pathname === "/privacyPolicy"
    ) {
      setColor({
        isLogoWhite: "1",
        menuColor: "#FFFFFF",
        headerBorderColor: "#FFFFFF",
        headerBgColor: "transparent",
        transform: "translateY(0%)",
        opacity: 1,
      });

      if (
        location.pathname === "/register" ||
        location.pathname === "/thankyou" ||
        location.pathname === "/privacyPolicy"
      ) {
        scrollhandleNoBnr();
      } else {
        scrollhandle();
      }
    } else {
      setColor({
        isLogoWhite: "0",
        menuColor: "#3B3B3B",
        headerBorderColor: "#3B3B3B",
        headerBgColor: "transparent",
        transform: "translateY(0%)",
        opacity: 1,
      });
      if (location.pathname === "/interiors-features") {
        scrollhandle();
      } else {
        scrollhandleNoBnr();
      }
    }
  });

  useEffect(() => {
  //   // header GSAP
  //   if (location.pathname === "/") {
  //     let ctx = gsap.context(() => {
  //       gsap.to(".header .wrapper", {
  //         opacity: 1,
  //         ease: "power2.easeOut",
  //         delay: 3,
  //         duration: 2,
  //       });
  //     });

  //     return () => ctx.revert();
  //   } else {
      let ctx = gsap.context(() => {
        gsap.to(".header .wrapper", {
          opacity: 1,
          ease: "power2.easeOut",
          delay: 0.82,
          duration: 0.2,
        });
      });

      return () => ctx.revert();
  //   }
}, []);
  // }, [location.pathname]);

  return (
    <>
      <header
        className="header"
        style={{
          opacity: isWhite.opacity,
          backgroundColor: isWhite.headerBgColor,
          transform: isWhite.transform,
          display:display
        }}
      >
        <div
          className="wrapper"
          style={{ borderBottomColor: isWhite.headerBorderColor }}
        >
          <div className="rergister-burger is-flex">
            <Link to="/register" className="btn-shannon mobile-version767">
              Register
            </Link>
            <button
              ref={(menuTextRef) =>
                menuTextRef?.style.setProperty(
                  "color",
                  isWhite.menuColor,
                  "important"
                )
              }
              className="menu-text"
              style={{ color: isWhite.menuColor }}
              onClick={() => setHide(!hide)}
            >
              <span className="desktop-version767">Menu</span>
              <div className="burger mobile-version767">
                <div className="">
                  <div className="line" style={{backgroundColor:isWhite.menuColor}}></div>
                  <div className="line" style={{backgroundColor:isWhite.menuColor}}></div>
                </div>
              </div>
            </button> 
          </div>
          <Link to="/">
            {Number(isWhite.isLogoWhite) ? (
              <svg
                className="logo logo-white"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 792 293.8"
                style={{ enableBackground: "new 0 0 792 293.8" }}
                xmlSpace="preserve"
              >
                <g>
                  <path
                    className="st0"
                    d="M150.9,152.9c0,7.3-3,12.6-9.1,15.9c-5.2,2.9-12.5,4.3-21.8,4.3c-11,0-17.8-2.6-21.9-6.3
                c-4.1-3.6-5.7-6.6-6.6-14.9h8.4l-3.6,4.6l0.3,1.3c0.4,1.6,1.7,4.3,4.4,6.7c2.7,2.4,7.1,4.3,13.8,4.3c9.1,0,16.6-3.1,20.8-7.5
                c2.1-2.2,3.1-4.6,3.1-7.1c0-0.9-0.2-1.9-0.5-2.9c-1.6-4.4-6.1-6.8-11.8-8.4c-2.9-0.8-6-1.5-9.2-2.1c-5.3-1-10.7-2.1-15.3-4.1
                c0.5,0.2-0.6-0.2-1.9-0.9c-0.4-0.2-0.8-0.4-1.1-0.6c-2.6-1.4-8.6-6.6-8.6-15c0-4.5,2.1-10,6.8-14.5c4.7-4.5,12.2-7.7,22.8-7.7
                c9.6,0,16.7,2.4,21.4,6.1c4.7,3.8,7,8.8,7,13.9h-8.7l3.5-5.1c-2.3-6.6-9.3-11-20.1-11c-6.9,0-12.1,2.1-15.6,5.2
                c-3.6,3.1-5.4,7.2-5.4,11.1c0,1.6,0.3,3.2,1,4.8c2.9,6,11.2,8,20.7,9.5c6.6,1.1,13.2,2.5,18.4,5.3
                C147.5,140.8,150.9,145.3,150.9,152.9z M203.5,147.5c0-7.5-2.5-12.2-6.2-15.2c-3.7-3.1-8.5-4.8-13.4-6.4c-2.9-0.9-5.6-1.9-8.2-3.1
                c-5.1-2.4-8.6-6.3-8.7-13.6v-7.7l3.2-3.3h-17.4l3.2,3.3v68.6l-3.2,3.3h17.4l-3.2-3.3v-52.8c0.2,4.5,4.2,6.6,9.4,8.7
                c3.6,1.6,7.6,3.4,10.8,6.5s5.3,7.6,5.3,14.6v22.9l-3.2,3.3h17.4l-3.2-3.3L203.5,147.5L203.5,147.5z M248.8,170.1l3.2,3.3h-17.4
                l3.2-3.3v-1.7c-4.4,4.2-9.4,6.1-14.1,6.1c-5.4,0-10.3-2.3-13.1-6.1c-1.4-1.9-2.1-4-2.1-6.4c0.2-3.3,1.1-5.8,2.8-7.6
                c3-3.1,7.5-4.6,12.2-6c3.6-1.1,7.1-2.1,9.8-4c3-2,4.4-4.9,4.4-8.8v-2.5c0-4.3-2.2-6.9-5.2-8.1c-1.6-0.6-3.1-0.9-4.8-0.9
                c-4.2,0-7.2,2.1-9.8,5.4l5.5,6.6H211c0-4.4,3.6-9.6,9.2-12.8c2.8-1.6,6-2.5,9.5-2.5c7.1,0,13.1,2.2,16.5,5.8
                c1.7,1.8,2.6,3.8,2.6,6.1L248.8,170.1L248.8,170.1z M237.8,141.1c0,2.4-1.7,4.4-4.1,6.1c-1.2,0.9-2.5,1.8-4,2.6
                c-3.2,1.9-6.3,4.1-8.3,6.8c-0.9,1.4-1.5,2.9-1.5,4.8c0,3.9,2.1,6.4,5.1,7.6c1.5,0.6,3.1,0.9,4.6,0.9c3.1,0,6.2-1.2,8-3.6
                L237.8,141.1L237.8,141.1z M301.3,129.1c0-6.1-2.3-8.1-5.6-8.1c-6.9,0-17.2,7.8-23.3,7.8c-2.4,0-3.9-1.3-4.4-3.9l3.2-3.3h-17.4
                l3.2,3.3v45.2l-3.2,3.3h17.4l-3.2-3.3v-40.2c0.9,0.5,2,0.8,3.2,0.8c4.5,0,11.5-3.3,15.3-3.3c2.2,0,3.8,1,3.8,4.2v38.6l-3.2,3.3
                h17.4l-3.2-3.3L301.3,129.1L301.3,129.1z M353.8,129.1c0-6.1-2.3-8.1-5.6-8.1c-6.9,0-17.2,7.8-23.3,7.8c-2.4,0-3.9-1.3-4.4-3.9
                l3.2-3.3h-17.4l3.2,3.3v45.2l-3.2,3.3h17.4l-3.2-3.3v-40.2c0.9,0.5,2,0.8,3.2,0.8c4.5,0,11.5-3.3,15.3-3.3c2.2,0,3.8,1,3.8,4.2
                v38.6l-3.2,3.3h17.4l-3.2-3.3L353.8,129.1L353.8,129.1z M410.8,133.9c2.5,4.1,3.8,8.5,3.8,13.4c0,9.8-5.5,18.2-13.9,23
                c-4.2,2.4-8.9,3.6-14,3.6c-10.2,0-19.1-5.2-24.1-13.2c-2.5-4-3.8-8.5-3.8-13.3c0-9.8,5.4-18.2,13.9-23c4.2-2.4,8.9-3.6,14-3.6
                C396.9,120.7,405.7,125.8,410.8,133.9z M402.1,147.2c0-8.9-3-16.6-7.7-20.9c-2.3-2.1-4.9-3.2-7.8-3.2c-5.7,0-10.6,4.7-13.4,12
                c-1.4,3.7-2.1,7.7-2.1,12.1c0,8.9,3,16.6,7.7,20.9c2.3,2.2,4.9,3.3,7.8,3.3c5.7,0,10.6-4.7,13.4-12.1
                C401.4,155.7,402.1,151.7,402.1,147.2z M474,174.8c3.1-0.3,5.8-1.2,9.1-3.1s7.2-5.1,11.7-9.3c0.8-0.7,1.5-1.5,2.3-2.2
                c-0.4,0.7-0.7,1.3-1,1.9c-1,2.1-1.5,4.1-1.5,5.9c0,2,0.6,3.7,2,4.9c1.3,1.2,3.4,1.8,6.4,1.8c3.1,0,6-0.7,8.9-2.2
                c2.9-1.5,5.6-3.3,8.1-5.4c2.5-2.1,4.9-4.4,7-6.7s3.9-4.4,5.4-6.2l0.3,0.3c-2.2,3.4-4.7,7.3-7.3,11.6c-2.6,4.3-5.5,8.7-8.5,13.2
                c-3.1,4.5-6.3,8.8-9.7,13.1c-3.4,4.2-7,8-10.8,11.3c-3.8,3.3-7.7,6-11.7,8.1s-8.3,3.1-12.6,3.1c-2,0-4-0.3-5.9-1
                c-1.9-0.6-3.6-1.6-5.1-2.8c-1.5-1.2-2.7-2.7-3.6-4.5c-0.9-1.8-1.4-3.9-1.4-6.2c0-3.4,0.8-6.3,2.4-8.5c1.6-2.3,4-3.8,7.4-4.5v-0.4
                c-4.5,0-8.4,1.1-11.6,3.3s-4.8,5.7-4.8,10.6c0,2.2,0.6,4.3,1.8,6.2c1.2,1.9,2.8,3.6,4.9,5c2,1.4,4.4,2.5,7.2,3.3
                c2.7,0.8,5.6,1.2,8.7,1.2c6.8,0,12.8-1.3,18.2-3.8c5.4-2.6,10.3-5.8,14.8-9.9c4.5-4,8.6-8.6,12.4-13.6c3.8-5.1,7.4-10,11-14.9
                c8.7-1.4,17.3-5.2,25.8-11.3c2.6-1.9,5.2-4,7.6-6.3c-1,1.6-2,3.1-3,4.7c-2.7,4.2-5.3,8.4-7.7,12.4h8.6c1-2.1,2.5-4.8,4.6-8
                c2-3.2,4.3-6.5,7-10c2.6-3.5,5.4-6.9,8.4-10.4c3-3.5,6-6.6,9-9.4c3-2.8,6-5.1,8.8-6.8c2.9-1.7,5.5-2.6,7.8-2.6
                c1.2,0,2.2,0.3,2.9,0.8c0.7,0.6,1.1,1.4,1.1,2.5c0,2.3-1.1,5-3.3,8.1c-2.2,3.1-4.7,6.2-7.4,9.6c-2.7,3.3-5.1,6.6-7.4,9.9
                c-2.2,3.3-3.3,6.3-3.3,9.1c0,2.6,0.9,4.6,2.6,6.1s4,2.2,6.7,2.2c2,0,4.1-0.4,6.2-1.3s4.2-2.1,6.3-3.6s4.3-3.3,6.4-5.4
                c2.1-2,4.2-4.2,6.2-6.4c0.2-0.2,0.4-0.4,0.5-0.6c-0.8,1.2-1.6,2.5-2.4,3.7c-2.7,4.2-5.3,8.4-7.7,12.4h8.6c1-2.1,2.5-4.8,4.6-8
                c2-3.2,4.3-6.5,7-10c2.6-3.5,5.4-6.9,8.4-10.4c3-3.5,6-6.6,9-9.4c3-2.8,6-5.1,8.8-6.8c2.9-1.7,5.5-2.6,7.8-2.6
                c1.2,0,2.2,0.3,2.9,0.8c0.7,0.6,1.1,1.4,1.1,2.5c0,2.3-1.1,5-3.3,8.1c-2.2,3.1-4.7,6.2-7.4,9.6c-2.7,3.3-5.1,6.6-7.4,9.9
                c-2.2,3.3-3.3,6.3-3.3,9.1c0,2.6,0.9,4.6,2.6,6.1s4,2.2,6.7,2.2c2,0,4.1-0.4,6.2-1.3s4.2-2.1,6.3-3.6s4.3-3.3,6.4-5.4
                c2.1-2,4.2-4.2,6.2-6.4c3.4-3.7,6.6-7.5,9.5-11.3c2.9-3.8,5.3-7.2,7.2-9.9l-1-0.6c-0.8,1.3-2,2.9-3.4,4.9c-1.4,1.9-3,4-4.8,6.2
                c-1.8,2.2-3.6,4.4-5.5,6.6c-1.9,2.2-3.8,4.3-5.6,6.1c-4.6,4.7-8.6,8.1-12,10.1c-3.3,2-6.5,3-9.5,3c-2.6,0-3.9-1.3-3.9-3.9
                c0-1.2,0.5-2.7,1.6-4.5c1.1-1.8,2.4-3.8,4-6c1.6-2.2,3.3-4.5,5.1-6.9c1.8-2.4,3.6-4.8,5.1-7.1c1.6-2.3,2.9-4.5,4-6.5
                c1.1-2,1.6-3.8,1.6-5.3c0-2.4-0.8-4.4-2.3-5.9c-1.5-1.5-3.7-2.3-6.5-2.3c-3.2,0-6.3,0.9-9.5,2.8c-3.2,1.9-6.3,4.2-9.2,7
                s-5.7,5.7-8.3,8.8c-2.6,3.1-4.9,5.8-6.8,8.1l-0.3-0.3l18.3-25.4l-9,0.4c-2,3.1-4.2,6.6-6.6,10.5c-2.5,3.8-5,7.8-7.7,11.9
                c-0.1,0.2-0.3,0.4-0.4,0.7c-1.2,1.5-2.5,3-3.8,4.5c-1.9,2.2-3.8,4.3-5.6,6.1c-4.6,4.7-8.6,8.1-12,10.1c-3.3,2-6.5,3-9.5,3
                c-2.6,0-3.9-1.3-3.9-3.9c0-1.2,0.5-2.7,1.6-4.5c1.1-1.8,2.4-3.8,4-6c1.6-2.2,3.3-4.5,5.1-6.9c1.8-2.4,3.6-4.8,5.1-7.1
                c1.6-2.3,2.9-4.5,4-6.5c1.1-2,1.6-3.8,1.6-5.3c0-2.4-0.8-4.4-2.3-5.9c-1.5-1.5-3.7-2.3-6.5-2.3c-3.2,0-6.3,0.9-9.5,2.8
                c-3.2,1.9-6.3,4.2-9.2,7s-5.7,5.7-8.3,8.8c-2.6,3.1-4.9,5.8-6.8,8.1l-0.3-0.3l18.3-25.4l-9,0.4c-2,3.1-4.2,6.6-6.6,10.5
                c-2.5,3.8-5,7.8-7.7,11.9c-0.6,0.9-1.2,1.8-1.7,2.7c-3.3,3.5-6.8,6.7-10.6,9.6c-8,6.2-16.4,10-25.2,11.6
                c5.2-7.8,10.1-15.6,14.9-23.6s9.8-15.6,15.3-23.1h-8.6c-3.8,6.1-7.8,12-11.9,17.6s-8.2,10.6-12.2,14.9c-4,4.3-7.8,7.8-11.3,10.4
                c-3.6,2.6-6.7,3.9-9.4,3.9c-2.6,0-3.9-1.3-3.9-4c0-1.7,0.8-3.9,2.5-6.7s3.8-6.1,6.5-9.8s5.6-7.8,9-12.2c3.3-4.4,6.7-9.1,10-13.9
                h-9.3c-4.6,6.8-8.6,12.4-11.9,16.9c-3.2,4.5-5.9,8.2-8,11.4c-2.7,3.1-5.4,5.8-8.1,8.3c-3.6,3.3-6.9,5.9-10,7.8s-5.8,2.9-8.1,2.9
                c-2,0-3.1-1.2-3.1-3.5c0-1.1,0.3-2.4,0.9-4c0.6-1.6,1.7-3.7,3.3-6.4s3.8-6.1,6.5-10.4c2.8-4.2,6.3-9.6,10.7-16
                c4.4-6.5,9.6-14.3,15.7-23.4c6.1-9.1,13.3-19.9,21.5-32.4h-9.7c-7.1,11.3-13.4,21.1-18.9,29.5c-5.5,8.4-10.2,15.6-14.2,21.7
                c-4,6.1-7.4,11.2-10.1,15.4c-2.7,4.2-4.9,7.7-6.6,10.6s-2.9,5.4-3.5,7.5s-1,4.1-1,6c0,1.4,0.3,2.7,0.9,3.8
                c-1.8-1.8-3.2-5.3-3.2-13.6v-28.5c0-6.1-2.3-8.1-5.6-8.1c-6.9,0-17.2,7.8-23.3,7.8c-2.4,0-3.9-1.3-4.4-3.9l3.2-3.3h-17.4l3.2,3.3
                v45.2l-3.2,3.3h17.4l-3.2-3.3v-40.2c0.9,0.5,2,0.8,3.2,0.8c4.5,0,11.5-3.3,15.3-3.3c2.2,0,3.8,1,3.8,4.2c0,0,0,22.1,0,26.1
                C452.8,163.6,455.8,176.5,474,174.8z"
                  />
                  <g>
                    <g>
                      <path
                        className="st0"
                        d="M541.1,182.9c-3.5,0-6.1,2.7-6.1,5.9v0c0,3.2,2.5,5.9,6.1,5.9s6.1-2.7,6.1-5.9v0
                    C547.2,185.5,544.6,182.9,541.1,182.9z M544.5,188.8c0,2-1.4,3.6-3.4,3.6c-2,0-3.5-1.6-3.5-3.6v0c0-2,1.4-3.6,3.4-3.6
                    S544.5,186.9,544.5,188.8L544.5,188.8L544.5,188.8z"
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="559.5,190.1 554.2,183.1 551.9,183.1 551.9,194.5 554.3,194.5 554.3,187.2 559.9,194.5 562,194.5 
                    562,183.1 559.5,183.1 			"
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="573.8,185.4 577.2,185.4 577.2,194.5 579.8,194.5 579.8,185.4 583.2,185.4 583.2,183.1 573.8,183.1 
                          "
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="594.8,187.6 590.1,187.6 590.1,183.1 587.6,183.1 587.6,194.5 590.1,194.5 590.1,189.9 594.8,189.9 
                    594.8,194.5 597.3,194.5 597.3,183.1 594.8,183.1 			"
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="605,189.9 610.4,189.9 610.4,187.6 605,187.6 605,185.3 611.1,185.3 611.1,183.1 602.5,183.1 
                    602.5,194.5 611.2,194.5 611.2,192.3 605,192.3 			"
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="625.8,183.1 623.2,183.1 623.2,194.5 631.5,194.5 631.5,192.2 625.8,192.2 			"
                      />
                    </g>
                    <g>
                      <path
                        className="st0"
                        d="M639.7,183l-4.9,11.5h2.6l1-2.6h4.8l1,2.6h2.6l-4.9-11.5H639.7z M639.3,189.7l1.5-3.7l1.5,3.7H639.3z"
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="661.4,183.1 658.3,183.1 653.7,188.1 653.7,183.1 651.2,183.1 651.2,194.5 653.7,194.5 653.7,191 
                    655,189.6 658.6,194.5 661.6,194.5 656.7,187.9 			"
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="668,189.9 673.5,189.9 673.5,187.6 668,187.6 668,185.3 674.2,185.3 674.2,183.1 665.5,183.1 
                    665.5,194.5 674.3,194.5 674.3,192.3 668,192.3 			"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            ) : (
              <svg
                className="logo logo-black"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 792 293.8"
                style={{ enableBackground: "new 0 0 792 293.8" }}
                xmlSpace="preserve"
              >
                <g>
                  <path
                    className="st0"
                    d="M150.9,152.9c0,7.3-3,12.6-9.1,15.9c-5.2,2.9-12.5,4.3-21.8,4.3c-11,0-17.8-2.6-21.9-6.3
              c-4.1-3.6-5.7-6.6-6.6-14.9h8.4l-3.6,4.6l0.3,1.3c0.4,1.6,1.7,4.3,4.4,6.7c2.7,2.4,7.1,4.3,13.8,4.3c9.1,0,16.6-3.1,20.8-7.5
              c2.1-2.2,3.1-4.6,3.1-7.1c0-0.9-0.2-1.9-0.5-2.9c-1.6-4.4-6.1-6.8-11.8-8.4c-2.9-0.8-6-1.5-9.2-2.1c-5.3-1-10.7-2.1-15.3-4.1
              c0.5,0.2-0.6-0.2-1.9-0.9c-0.4-0.2-0.8-0.4-1.1-0.6c-2.6-1.4-8.6-6.6-8.6-15c0-4.5,2.1-10,6.8-14.5c4.7-4.5,12.2-7.7,22.8-7.7
              c9.6,0,16.7,2.4,21.4,6.1c4.7,3.8,7,8.8,7,13.9h-8.7l3.5-5.1c-2.3-6.6-9.3-11-20.1-11c-6.9,0-12.1,2.1-15.6,5.2
              c-3.6,3.1-5.4,7.2-5.4,11.1c0,1.6,0.3,3.2,1,4.8c2.9,6,11.2,8,20.7,9.5c6.6,1.1,13.2,2.5,18.4,5.3
              C147.5,140.8,150.9,145.3,150.9,152.9z M203.5,147.5c0-7.5-2.5-12.2-6.2-15.2c-3.7-3.1-8.5-4.8-13.4-6.4c-2.9-0.9-5.6-1.9-8.2-3.1
              c-5.1-2.4-8.6-6.3-8.7-13.6v-7.7l3.2-3.3h-17.4l3.2,3.3v68.6l-3.2,3.3h17.4l-3.2-3.3v-52.8c0.2,4.5,4.2,6.6,9.4,8.7
              c3.6,1.6,7.6,3.4,10.8,6.5s5.3,7.6,5.3,14.6v22.9l-3.2,3.3h17.4l-3.2-3.3L203.5,147.5L203.5,147.5z M248.8,170.1l3.2,3.3h-17.4
              l3.2-3.3v-1.7c-4.4,4.2-9.4,6.1-14.1,6.1c-5.4,0-10.3-2.3-13.1-6.1c-1.4-1.9-2.1-4-2.1-6.4c0.2-3.3,1.1-5.8,2.8-7.6
              c3-3.1,7.5-4.6,12.2-6c3.6-1.1,7.1-2.1,9.8-4c3-2,4.4-4.9,4.4-8.8v-2.5c0-4.3-2.2-6.9-5.2-8.1c-1.6-0.6-3.1-0.9-4.8-0.9
              c-4.2,0-7.2,2.1-9.8,5.4l5.5,6.6H211c0-4.4,3.6-9.6,9.2-12.8c2.8-1.6,6-2.5,9.5-2.5c7.1,0,13.1,2.2,16.5,5.8
              c1.7,1.8,2.6,3.8,2.6,6.1L248.8,170.1L248.8,170.1z M237.8,141.1c0,2.4-1.7,4.4-4.1,6.1c-1.2,0.9-2.5,1.8-4,2.6
              c-3.2,1.9-6.3,4.1-8.3,6.8c-0.9,1.4-1.5,2.9-1.5,4.8c0,3.9,2.1,6.4,5.1,7.6c1.5,0.6,3.1,0.9,4.6,0.9c3.1,0,6.2-1.2,8-3.6
              L237.8,141.1L237.8,141.1z M301.3,129.1c0-6.1-2.3-8.1-5.6-8.1c-6.9,0-17.2,7.8-23.3,7.8c-2.4,0-3.9-1.3-4.4-3.9l3.2-3.3h-17.4
              l3.2,3.3v45.2l-3.2,3.3h17.4l-3.2-3.3v-40.2c0.9,0.5,2,0.8,3.2,0.8c4.5,0,11.5-3.3,15.3-3.3c2.2,0,3.8,1,3.8,4.2v38.6l-3.2,3.3
              h17.4l-3.2-3.3L301.3,129.1L301.3,129.1z M353.8,129.1c0-6.1-2.3-8.1-5.6-8.1c-6.9,0-17.2,7.8-23.3,7.8c-2.4,0-3.9-1.3-4.4-3.9
              l3.2-3.3h-17.4l3.2,3.3v45.2l-3.2,3.3h17.4l-3.2-3.3v-40.2c0.9,0.5,2,0.8,3.2,0.8c4.5,0,11.5-3.3,15.3-3.3c2.2,0,3.8,1,3.8,4.2
              v38.6l-3.2,3.3h17.4l-3.2-3.3L353.8,129.1L353.8,129.1z M410.8,133.9c2.5,4.1,3.8,8.5,3.8,13.4c0,9.8-5.5,18.2-13.9,23
              c-4.2,2.4-8.9,3.6-14,3.6c-10.2,0-19.1-5.2-24.1-13.2c-2.5-4-3.8-8.5-3.8-13.3c0-9.8,5.4-18.2,13.9-23c4.2-2.4,8.9-3.6,14-3.6
              C396.9,120.7,405.7,125.8,410.8,133.9z M402.1,147.2c0-8.9-3-16.6-7.7-20.9c-2.3-2.1-4.9-3.2-7.8-3.2c-5.7,0-10.6,4.7-13.4,12
              c-1.4,3.7-2.1,7.7-2.1,12.1c0,8.9,3,16.6,7.7,20.9c2.3,2.2,4.9,3.3,7.8,3.3c5.7,0,10.6-4.7,13.4-12.1
              C401.4,155.7,402.1,151.7,402.1,147.2z M474,174.8c3.1-0.3,5.8-1.2,9.1-3.1s7.2-5.1,11.7-9.3c0.8-0.7,1.5-1.5,2.3-2.2
              c-0.4,0.7-0.7,1.3-1,1.9c-1,2.1-1.5,4.1-1.5,5.9c0,2,0.6,3.7,2,4.9c1.3,1.2,3.4,1.8,6.4,1.8c3.1,0,6-0.7,8.9-2.2
              c2.9-1.5,5.6-3.3,8.1-5.4c2.5-2.1,4.9-4.4,7-6.7s3.9-4.4,5.4-6.2l0.3,0.3c-2.2,3.4-4.7,7.3-7.3,11.6c-2.6,4.3-5.5,8.7-8.5,13.2
              c-3.1,4.5-6.3,8.8-9.7,13.1c-3.4,4.2-7,8-10.8,11.3c-3.8,3.3-7.7,6-11.7,8.1s-8.3,3.1-12.6,3.1c-2,0-4-0.3-5.9-1
              c-1.9-0.6-3.6-1.6-5.1-2.8c-1.5-1.2-2.7-2.7-3.6-4.5c-0.9-1.8-1.4-3.9-1.4-6.2c0-3.4,0.8-6.3,2.4-8.5c1.6-2.3,4-3.8,7.4-4.5v-0.4
              c-4.5,0-8.4,1.1-11.6,3.3s-4.8,5.7-4.8,10.6c0,2.2,0.6,4.3,1.8,6.2c1.2,1.9,2.8,3.6,4.9,5c2,1.4,4.4,2.5,7.2,3.3
              c2.7,0.8,5.6,1.2,8.7,1.2c6.8,0,12.8-1.3,18.2-3.8c5.4-2.6,10.3-5.8,14.8-9.9c4.5-4,8.6-8.6,12.4-13.6c3.8-5.1,7.4-10,11-14.9
              c8.7-1.4,17.3-5.2,25.8-11.3c2.6-1.9,5.2-4,7.6-6.3c-1,1.6-2,3.1-3,4.7c-2.7,4.2-5.3,8.4-7.7,12.4h8.6c1-2.1,2.5-4.8,4.6-8
              c2-3.2,4.3-6.5,7-10c2.6-3.5,5.4-6.9,8.4-10.4c3-3.5,6-6.6,9-9.4c3-2.8,6-5.1,8.8-6.8c2.9-1.7,5.5-2.6,7.8-2.6
              c1.2,0,2.2,0.3,2.9,0.8c0.7,0.6,1.1,1.4,1.1,2.5c0,2.3-1.1,5-3.3,8.1c-2.2,3.1-4.7,6.2-7.4,9.6c-2.7,3.3-5.1,6.6-7.4,9.9
              c-2.2,3.3-3.3,6.3-3.3,9.1c0,2.6,0.9,4.6,2.6,6.1s4,2.2,6.7,2.2c2,0,4.1-0.4,6.2-1.3s4.2-2.1,6.3-3.6s4.3-3.3,6.4-5.4
              c2.1-2,4.2-4.2,6.2-6.4c0.2-0.2,0.4-0.4,0.5-0.6c-0.8,1.2-1.6,2.5-2.4,3.7c-2.7,4.2-5.3,8.4-7.7,12.4h8.6c1-2.1,2.5-4.8,4.6-8
              c2-3.2,4.3-6.5,7-10c2.6-3.5,5.4-6.9,8.4-10.4c3-3.5,6-6.6,9-9.4c3-2.8,6-5.1,8.8-6.8c2.9-1.7,5.5-2.6,7.8-2.6
              c1.2,0,2.2,0.3,2.9,0.8c0.7,0.6,1.1,1.4,1.1,2.5c0,2.3-1.1,5-3.3,8.1c-2.2,3.1-4.7,6.2-7.4,9.6c-2.7,3.3-5.1,6.6-7.4,9.9
              c-2.2,3.3-3.3,6.3-3.3,9.1c0,2.6,0.9,4.6,2.6,6.1s4,2.2,6.7,2.2c2,0,4.1-0.4,6.2-1.3s4.2-2.1,6.3-3.6s4.3-3.3,6.4-5.4
              c2.1-2,4.2-4.2,6.2-6.4c3.4-3.7,6.6-7.5,9.5-11.3c2.9-3.8,5.3-7.2,7.2-9.9l-1-0.6c-0.8,1.3-2,2.9-3.4,4.9c-1.4,1.9-3,4-4.8,6.2
              c-1.8,2.2-3.6,4.4-5.5,6.6c-1.9,2.2-3.8,4.3-5.6,6.1c-4.6,4.7-8.6,8.1-12,10.1c-3.3,2-6.5,3-9.5,3c-2.6,0-3.9-1.3-3.9-3.9
              c0-1.2,0.5-2.7,1.6-4.5c1.1-1.8,2.4-3.8,4-6c1.6-2.2,3.3-4.5,5.1-6.9c1.8-2.4,3.6-4.8,5.1-7.1c1.6-2.3,2.9-4.5,4-6.5
              c1.1-2,1.6-3.8,1.6-5.3c0-2.4-0.8-4.4-2.3-5.9c-1.5-1.5-3.7-2.3-6.5-2.3c-3.2,0-6.3,0.9-9.5,2.8c-3.2,1.9-6.3,4.2-9.2,7
              s-5.7,5.7-8.3,8.8c-2.6,3.1-4.9,5.8-6.8,8.1l-0.3-0.3l18.3-25.4l-9,0.4c-2,3.1-4.2,6.6-6.6,10.5c-2.5,3.8-5,7.8-7.7,11.9
              c-0.1,0.2-0.3,0.4-0.4,0.7c-1.2,1.5-2.5,3-3.8,4.5c-1.9,2.2-3.8,4.3-5.6,6.1c-4.6,4.7-8.6,8.1-12,10.1c-3.3,2-6.5,3-9.5,3
              c-2.6,0-3.9-1.3-3.9-3.9c0-1.2,0.5-2.7,1.6-4.5c1.1-1.8,2.4-3.8,4-6c1.6-2.2,3.3-4.5,5.1-6.9c1.8-2.4,3.6-4.8,5.1-7.1
              c1.6-2.3,2.9-4.5,4-6.5c1.1-2,1.6-3.8,1.6-5.3c0-2.4-0.8-4.4-2.3-5.9c-1.5-1.5-3.7-2.3-6.5-2.3c-3.2,0-6.3,0.9-9.5,2.8
              c-3.2,1.9-6.3,4.2-9.2,7s-5.7,5.7-8.3,8.8c-2.6,3.1-4.9,5.8-6.8,8.1l-0.3-0.3l18.3-25.4l-9,0.4c-2,3.1-4.2,6.6-6.6,10.5
              c-2.5,3.8-5,7.8-7.7,11.9c-0.6,0.9-1.2,1.8-1.7,2.7c-3.3,3.5-6.8,6.7-10.6,9.6c-8,6.2-16.4,10-25.2,11.6
              c5.2-7.8,10.1-15.6,14.9-23.6s9.8-15.6,15.3-23.1h-8.6c-3.8,6.1-7.8,12-11.9,17.6s-8.2,10.6-12.2,14.9c-4,4.3-7.8,7.8-11.3,10.4
              c-3.6,2.6-6.7,3.9-9.4,3.9c-2.6,0-3.9-1.3-3.9-4c0-1.7,0.8-3.9,2.5-6.7s3.8-6.1,6.5-9.8s5.6-7.8,9-12.2c3.3-4.4,6.7-9.1,10-13.9
              h-9.3c-4.6,6.8-8.6,12.4-11.9,16.9c-3.2,4.5-5.9,8.2-8,11.4c-2.7,3.1-5.4,5.8-8.1,8.3c-3.6,3.3-6.9,5.9-10,7.8s-5.8,2.9-8.1,2.9
              c-2,0-3.1-1.2-3.1-3.5c0-1.1,0.3-2.4,0.9-4c0.6-1.6,1.7-3.7,3.3-6.4s3.8-6.1,6.5-10.4c2.8-4.2,6.3-9.6,10.7-16
              c4.4-6.5,9.6-14.3,15.7-23.4c6.1-9.1,13.3-19.9,21.5-32.4h-9.7c-7.1,11.3-13.4,21.1-18.9,29.5c-5.5,8.4-10.2,15.6-14.2,21.7
              c-4,6.1-7.4,11.2-10.1,15.4c-2.7,4.2-4.9,7.7-6.6,10.6s-2.9,5.4-3.5,7.5s-1,4.1-1,6c0,1.4,0.3,2.7,0.9,3.8
              c-1.8-1.8-3.2-5.3-3.2-13.6v-28.5c0-6.1-2.3-8.1-5.6-8.1c-6.9,0-17.2,7.8-23.3,7.8c-2.4,0-3.9-1.3-4.4-3.9l3.2-3.3h-17.4l3.2,3.3
              v45.2l-3.2,3.3h17.4l-3.2-3.3v-40.2c0.9,0.5,2,0.8,3.2,0.8c4.5,0,11.5-3.3,15.3-3.3c2.2,0,3.8,1,3.8,4.2c0,0,0,22.1,0,26.1
              C452.8,163.6,455.8,176.5,474,174.8z"
                  />
                  <g>
                    <g>
                      <path
                        className="st0"
                        d="M541.1,182.9c-3.5,0-6.1,2.7-6.1,5.9v0c0,3.2,2.5,5.9,6.1,5.9s6.1-2.7,6.1-5.9v0
                  C547.2,185.5,544.6,182.9,541.1,182.9z M544.5,188.8c0,2-1.4,3.6-3.4,3.6c-2,0-3.5-1.6-3.5-3.6v0c0-2,1.4-3.6,3.4-3.6
                  S544.5,186.9,544.5,188.8L544.5,188.8L544.5,188.8z"
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="559.5,190.1 554.2,183.1 551.9,183.1 551.9,194.5 554.3,194.5 554.3,187.2 559.9,194.5 562,194.5 
                  562,183.1 559.5,183.1 			"
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="573.8,185.4 577.2,185.4 577.2,194.5 579.8,194.5 579.8,185.4 583.2,185.4 583.2,183.1 573.8,183.1 
                        "
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="594.8,187.6 590.1,187.6 590.1,183.1 587.6,183.1 587.6,194.5 590.1,194.5 590.1,189.9 594.8,189.9 
                  594.8,194.5 597.3,194.5 597.3,183.1 594.8,183.1 			"
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="605,189.9 610.4,189.9 610.4,187.6 605,187.6 605,185.3 611.1,185.3 611.1,183.1 602.5,183.1 
                  602.5,194.5 611.2,194.5 611.2,192.3 605,192.3 			"
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="625.8,183.1 623.2,183.1 623.2,194.5 631.5,194.5 631.5,192.2 625.8,192.2 			"
                      />
                    </g>
                    <g>
                      <path
                        className="st0"
                        d="M639.7,183l-4.9,11.5h2.6l1-2.6h4.8l1,2.6h2.6l-4.9-11.5H639.7z M639.3,189.7l1.5-3.7l1.5,3.7H639.3z"
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="661.4,183.1 658.3,183.1 653.7,188.1 653.7,183.1 651.2,183.1 651.2,194.5 653.7,194.5 653.7,191 
                  655,189.6 658.6,194.5 661.6,194.5 656.7,187.9 			"
                      />
                    </g>
                    <g>
                      <polygon
                        className="st0"
                        points="668,189.9 673.5,189.9 673.5,187.6 668,187.6 668,185.3 674.2,185.3 674.2,183.1 665.5,183.1 
                  665.5,194.5 674.3,194.5 674.3,192.3 668,192.3 			"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            )}
          </Link>
          <Link to="/register" className="btn-shannon desktop-version767">
            Register Now
          </Link>
        </div>
      </header>
      <Menu hide={hide} onShow={() => setHide(!hide)} />
    </>
  );
};

export default Header;
