import { useRef, useEffect, useState } from "react";
import Select from "react-select";
//refer：Invisible reCAPTCHA  https://www.npmjs.com/package/react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";
import "./style.css";

const SparkForm = () => {
  const recaptchaRef = useRef();
  const bedroomType = [
    {
      value: "2 Bedroom",
      label: "2 Bedroom",
    },
    { value: "3 Bedroom", label: "3 Bedroom" },
    { value: "4 Bedroom", label: "4 Bedroom" },
  ];
  const options = [
    { value: "Google Search", label: "Google Search" },
    { value: "Instagram", label: "Instagram" },
    { value: "Facebook", label: "Facebook" },
    { value: "Realtor", label: "Realtor" },
    { value: "Online News Source", label: "Online News Source" },
    { value: "Signage", label: "Signage" },
    { value: "Radio", label: "Radio" },
    { value: "Magazine", label: "Magazine" },
    { value: "Word of Mouth", label: "Word of Mouth" },
  ];
  const RealtorOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      // match with the menu
      borderRadius: "none",
      // Overwrittes the different states of border
      borderColor: "none",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "none",
      },
      color: "#FFFFFF",
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const [formData, setFormData] = useState({
    email: null,
    fname: null,
    lname: null,
    phone: null,
    city: null,
    bedroomType: null,
    hearUs: "Select",
    realtorStatus: null,
    realtorBrokerage: null,
    workRealtorStatus: null,
    workRealtorBrokerage: null,
    allowContact: "No",
  });

  const changeHandler = (event) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const changeSelectRealtorStatusHandler = (event) => {
    setFormData({
      ...formData,
      realtorStatus: event.value,
    });
  };
  const changeSelectHandler = (event) => {
    setFormData({
      ...formData,
      hearUs: event.value,
    });
  };
  const changeSelectWorkRealtorStatusHandler = (event) => {
    setFormData({
      ...formData,
      workRealtorStatus: event.value,
    });
  };
  const allowContactHandler = (event) => {
    if (event.target.checked) {
      setFormData({
        ...formData,
        allowContact: "Yes",
      });
    } else {
      setFormData({
        ...formData,
        allowContact: "No",
      });
    }
  };

  return (
    <form
      id="contact-form"
      className="form"
      action="https://spark.re/ace-project-marketing/shannonlynn-on-the-lake/register/registration"
      accept-charset="UTF-8"
      method="post"
      onSubmit={() => {
        recaptchaRef.current.execute();
      }}
    >
      <input
        type="hidden"
        name="authenticity_token"
        value="a+Yo3gktjOwtzR7UTSjfc69wttLKcX2xGOdP/Ky/8OU6Z+6mgU59WijoEcE1YgGeDRbpf4n0CaMrnmX37Vb3SA=="
        autocomplete="off"
      />
      <div className="details">
        <div className="wrapper">
          <input
            type="text"
            id="fname"
            name="contact[first_name]"
            value={formData.fname}
            onChange={changeHandler}
            className=""
            required={true}
          />
          <br />
          <label for="fname">*First Name</label>
        </div>
        <div className="wrapper">
          <input
            type="text"
            id="lname"
            name="contact[last_name]"
            value={formData.lname}
            onChange={changeHandler}
            className=""
            required={true}
          />
          <br />
          <label for="lname">*Last Name</label>
        </div>
        <div className="wrapper">
          <input
            id="email"
            name="contact[email]"
            type="email"
            value={formData.email}
            onChange={changeHandler}
            className=""
            required={true}
          />
          <br />
          <label for="email">*Email</label>
        </div>
        <div className="wrapper">
          <input
            type="text"
            id="phone"
            name="contact[phone]"
            value={formData.phone}
            onChange={changeHandler}
            className=""
            required={true}
          />
          <br />
          <label for="phone">*Phone Number</label>
        </div>
        <div className="wrapper">
          <input
            type="text"
            id="city"
            name="contact[city]"
            value={formData.city}
            onChange={changeHandler}
            className=""
            required={true}
          />
          <br />
          <label for="city">*City</label>
        </div>
        <div className="wrapper">
          <Select
            name="answers[21960][answers]"
            options={bedroomType}
            placeholder={<div className="selectPlaceholderText"></div>}
            className="registerSelect"
            onChange={changeSelectHandler}
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null,
            }}
            required={true}
          />
          <label for="HearUs">*Which floorplan interests you?</label>
        </div>
        <div className="wrapper">
          <Select
            name="answers[21961][answers]"
            options={options}
            placeholder={<div className="selectPlaceholderText"></div>}
            className="registerSelect"
            onChange={changeSelectHandler}
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null,
            }}
            required={true}
          />
          <label for="HearUs">*How did you hear about us?</label>
        </div>
        <div className="wrapper">
          <Select
            name="answers[21964][answers]"
            options={RealtorOptions}
            placeholder={<div className="selectPlaceholderText"></div>}
            className="registerSelect"
            onChange={changeSelectRealtorStatusHandler}
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null,
            }}
            required={true}
          />
          <label for="HearUs">*Are you at Realtor?</label>
        </div>
        <div
          className="wrapper"
          style={{
            display: formData.realtorStatus === "Yes" ? "block" : "none",
          }}
        >
          <input
            type="text"
            id="brokerage"
            name="answers[21965][answers]"
            value={formData.realtorStatus === "Yes" ? formData.realtorBrokerage : ""}
            onChange={changeHandler}
            className=""
          />
          <br />
          <label for="brokerage">*Brokerage Name</label>
        </div>
        <div className="wrapper">
          <Select
            name="answers[21962][answers]"
            options={RealtorOptions}
            placeholder={<div className="selectPlaceholderText"></div>}
            className="registerSelect"
            onChange={changeSelectWorkRealtorStatusHandler}
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null,
            }}
            required={true}
          />
          <label for="RealtorStatus">*Are you working with a Realtor?</label>
        </div>
        <div
          className="wrapper"
          style={{
            display: formData.workRealtorStatus === "Yes" ? "block" : "none",
          }}
        >
          <input
            type="text"
            id="brokerage"
            name="answers[21966][answers]"
            value={formData.workRealtorStatus === "Yes" ? formData.workRealtorBrokerage : ""}
            onChange={changeHandler}
            className=""
          />
          <br />
          <label for="brokerage">*Brokerage Name</label>
        </div>
      </div>
      <div className="checkbox-contianer">
        <div className="checkbox-wrapper">
          <input
            className="checkbox"
            onChange={allowContactHandler}
            type="checkbox"
            name="answers[21963][answers][]"
            value="Yes"
          />
          {/* spark: res[pmse type] */}
          <div className="agreement">
            *Please check box to confirm your interest in Shannon Lynn.
            <br />
            <span className="desktop-version is-small">
            You consent to receive further communications from Landmark West Group, Unik-Town Development, Injoy Development, Ace Project Marketing Group, and their affiliates
            regarding this and other real estate opportunities. Your information will not be sold or shared. You may rescind consent at any time.</span>
          </div>
        </div>
        <div className="mobile-version is-small">
        You consent to receive further communications from Landmark West Group, Unik-Town Development, Injoy Development, Ace Project Marketing Group, and their affiliates
        regarding this and other real estate opportunities. Your information will not be sold or shared. You may rescind consent at any time.
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LeGSNkpAAAAABto5qFHSOQmpXi0TKQZFtfGss_p"
      />
      <button
        className="btn-shannon submit-btn-shannon"
        type="submit"
        value="Submit"
      >
        Submit
      </button>
      <input
        type="hidden"
        name="source"
        id="source"
        value="Website"
        autocomplete="off"
      />
      <input
        type="hidden"
        name="redirect_success"
        id="redirect_success"
        value="https://shannonlynn.ca/thankyou"
        autocomplete="off"
      />
      <input
        type="hidden"
        name="redirect_error"
        id="redirect_error"
        value=""
        autocomplete="off"
      />
      <input
        type="text"
        name="are_you_simulated"
        id="are_you_simulated"
        placeholder="Leave this field blank"
        style={{ display: "none" }}
      />
      <input
        type="hidden"
        name="g-recaptcha-response"
        id="g-recaptcha-response"
        class="g-recaptcha-response"
        autocomplete="off"
      />
    </form>
  );
};

export default SparkForm;
