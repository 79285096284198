import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SparkForm from "../../../pages/Register/SparkForm";
import { nav } from "../../Data";
import "./style.css";

const Footer = ({ display }) => {
  const location = useLocation();

  const [visible, setVisible] = useState("visible");

  useEffect(() => {
    if (
      location.pathname === "/register" ||
      location.pathname === "/thankyou"
    ) {
      setVisible("hidden");
    } else {
      setVisible("visible");
    }
  }, [location.pathname]);

  return (
    <footer className="footer global-padding" style={{ display: display }}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 col-xl-7 col-xxl-5 left">
            <div className="nav-address-wrapper">
              <div className="nav-footer">
                <h3>Explore Shannon lynn</h3>
                <div className="-wrapper">
                  <ul>
                    {nav.map((navObj, index) => {
                      return (
                        <li key={index}>
                          <Link
                            to={navObj.path}
                            className="nav"
                            curr_index={index}
                          >
                            {navObj.text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                  <Link to="/register" className="btn-shannon register-btn">
                    Register Now
                  </Link>
                </div>
              </div>
              <div>
                <h3>PRESENTATION CENTRE</h3>
                <div className="wrapper address-wrapper">
                  <ul>
                    <li>By Appointment Only:</li>
                    <li>2739 Shannon Lake Road</li>
                    <li>Chantaal Doucett</li>
                    <li>778-997-7355</li>
                    <li>chantaal@theacepmg.com</li>
                  </ul>
                  <Link
                    to="https://calendly.com/shannonlynn-info/30min?month=2025-01"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-shannon book-btn"
                  >
                    Book a virtual tour
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xxl-6 right form-column">
            <div style={{ visibility: visible }}>
              <h3>Register your interest</h3>
              <SparkForm />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 left logo-list">
            <Link
              to="https://www.landmarkgroups.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="landmark-logo"
                src="../images/logo/Landmark-West-Logo-white.png"
                alt="Landmark logo"
              />
            </Link>
            <Link
            // to="https://www.lifestylemarketing.ca/"
            // target="_blank"
            // rel="noopener noreferrer"
            >
              <img
                className="unik-logo"
                src="../images/logo/unik-logo.png"
                alt="unik-logo"
              />
            </Link>
            <Link
              to="https://theacepmg.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="ace-logo"
                src="../images/logo/ACE-logo-white.png"
                alt="ACE-logo"
              />
            </Link>
            <Link
              to="https://www.oakwyn.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="oakwyn-logo"
                src="../images/logo/oakwyn-logo.png"
                alt="oakwyn-logo"
              />
            </Link>
          </div>
          <div className="col-12 col-sm-9 col--md-7 col-lg-6 right">
            <div className="copyright">
              © Shannon Lynn 2024 &nbsp; | &nbsp;
              <Link
                to="https://www.termsfeed.com/live/b6afda84-aa81-4ed5-9be5-2eef336fd6fa"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </Link>
              &nbsp; | &nbsp;
              <Link
                to="https://celero.site/s/c5d2b767/alab-creative-presentation-2024/?page=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                site by alab creative
              </Link>
            </div>
            <p>
              E. & O. E. This is not an offering for sale. An offering for sale
              may only be made after filing a Disclosure Statement under the
              Real Estate Development Marketing Act. Contact the developer’s
              agent for further details as well as a copy of the Disclosure
              Statement when available. After a Disclosure statement is filed,
              the developer reserves the right to make modifications to lot
              sizes, building design, specifications, features, amenities and
              floor plans. Renderings and images provided are an artist’s
              conception and are intended only as a general reference and are
              not to be relied upon. Prices are subject to change and can be
              withdrawn at any time.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
